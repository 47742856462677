<template>
  <SavvasQti3Player
    ref="qti3player"
    :container-class="containerClass"
    :container-padding-class="containerPaddingClass"
    :color-class="colorClass"
    suppress-alert-messages
    suppress-invalid-response-messages
    @notifyQti3PlayerReady="handlePlayerReady"
    @notifyQti3ItemReady="handleItemReady"
    @notifyQti3SuspendAttemptCompleted="handleSuspendAttemptCompleted"
    @notifyQti3EndAttemptCompleted="handleEndAttemptCompleted"
    @notifyQti3ItemAlertEvent="displayItemAlertEvent"
    @notifyQti3ItemCatalogEvent="handleItemCatalogEvent"
  />
</template>

<script>
import { ItemFactory } from '@/helpers/ItemFactory'
import { PnpFactory } from '@/helpers/PnpFactory'
import { SessionControlFactory } from '@/helpers/SessionControlFactory'

// The AmpQti3Player component and built-in CSS
import SavvasQti3Player from '@savvaslearning/savvas-qti3-item-player'
import '@savvaslearning/savvas-qti3-item-player/dist/savvasQti3Player.css'

// For displaying our own Toasts/Alerts
import Swal from 'sweetalert2'

export default {
  name: 'ContentView',

  components: {
    SavvasQti3Player
  },

  props: {
    identifier: {
      type: String,
      required: true,
      default: ''
    }
  },

  data () {
    return {
      /*
       * Qti3Player component instance
       */
      qti3Player: null,
      /*
       * This is our list of items, item XML, item GUID's
       */
      items: [
      ],
      /*
       * From the set of:
       *   qti3-player-container-fluid ***DEFAULT***
       *   qti3-player-container
       */
      containerClass: 'qti3-player-container-fluid',
      /*
       * From the set of:
       *   qti3-player-color-default ***DEFAULT***
       *   qti3-player-color-defaultreverse (Default - Reverse Polarity)
       *   qti3-player-color-blackwhite (High Contrast)
       *   qti3-player-color-whiteblack (High Contrast - Reverse Polarity)
       *   qti3-player-color-blackrose
       *   qti3-player-color-roseblack
       *   qti3-player-color-yellowblue
       *   qti3-player-color-blueyellow
       *   qti3-player-color-dgraymgray
       *   qti3-player-color-mgraydgray
       */
      colorClass: 'qti3-player-color-default',
      /*
       * From the set of:
       *   qti3-player-container-padding-0 { padding: 0; } ***DEFAULT***
       *   qti3-player-container-padding-1 { padding: 0.25rem; }
       *   qti3-player-container-padding-2 { padding: 0.5rem; }
       *   qti3-player-container-padding-3 { padding: 1rem; }
       *   qti3-player-container-padding-4 { padding: 1.5rem; }
       *   qti3-player-container-padding-5 { padding: 3rem; }
       */
      containerPaddingClass: 'qti3-player-container-padding-2',
      /*
       * Test's Item Session Control Factory
       */
      sessionControl: null,
      /*
       * Test's Pnp Factory
       */
      pnp: null,
      /*
       * From the set of: { 'individual' | 'simultaneous' }
       * This is used to tell the Qti3Player whether or not
       * we want to run response processing when we navigate
       * away from an item.
       * 'individual' --> invoke Qti3Player's endAttempt method
       * 'simultaneous' --> invoke Qti3Player's suspendAttempt method
       */
      testSubmissionMode: 'simultaneous',
      /*
       * An item can override submission mode
       */
      itemSubmissionMode: null
    }
  },

  watch: {
    /**
     * @description A menu change will trigger an identifier update.  Watch this
     * and load the appropriate item from the factory.
     */
    
    identifier () {
      let itemIndex = this.items.findIndex(item => item.identifier == this.identifier)
      this.loadItemAtIndex(itemIndex) 
    }
  },

  methods: {

    initialize () {
      // Load items...all of them.
      this.items = new ItemFactory().loadAll()

      // Load pnp
      this.pnp = new PnpFactory()
      //this.pnp.setGlossaryOnScreen(false)
      // Turn on keyword translation langauge Spanish
      this.pnp.setKeywordTranslationLanguage('es')
      // Turn on SBAC Illustrated Glossary
      this.pnp.setExtSbacGlossaryIllustration(true)

      // Load sessionControl
      this.sessionControl = new SessionControlFactory()
    },

    loadItemAtIndex (index) {
      let assessmentDetails = {
          status: '',
          userRole: '',
          isStartedAssessment: false,
          playerMode: '',
        }
      if (index === null) return
      if (index < 0) {
        this.qti3Player.loadItemFromXml(this.identifier, {}, assessmentDetails)
        return
      }

      // Set current item submission mode
      this.itemSubmissionMode = this.computeItemSubmissionMode(this.items[index])

      // Update sessionControl with item/test properties
      this.sessionControl.setValidateResponses(this.items[index].sessionControl.validateResponses)
      this.sessionControl.setShowFeedback(this.items[index].sessionControl.showFeedback)
      this.sessionControl.setShowFeedback(true)

      // Build a configuration
      const configuration = this.getConfiguration(this.items[index].guid)
      // **********************************************
      // Load the QTI 3 item xml with the configuration
      // **********************************************
      this.qti3Player.loadItemFromXml(this.items[index].xml, configuration, assessmentDetails)
    },

    computeItemSubmissionMode (itemObject) {
      if ('sessionControl' in itemObject) return itemObject.sessionControl.submissionMode
      return this.testSubmissionMode
    },

    setTestStateItemState (state) {
      console.log('[Controller][SetItemState][' + state.guid + ']', state)
    },

    getTestStateItemState (/* guid */) {
      // In real life, this would pull any item state.  In Savvas-Player
      // this is a NOOP.  Return undefined.
      return undefined
    },

    getConfiguration (guid) {
      const configuration = {}

      // Fetch prior state from Test State
      const state = this.getTestStateItemState(guid)
      if (typeof state !== 'undefined') configuration.state = state

      // IMPORTANT: Stamp the item's tracking guid onto the configuration
      configuration.guid = guid
      configuration.pnp = this.pnp.getPnp()
      configuration.sessionControl = this.sessionControl.getSessionControl()

      return configuration
    },

    handleEndAttemptCompleted (data) {
      console.log('[EndAttemptCompleted]', data)
      this.$emit('endAttemptComplete', data);
      this.evaluateResults(data)
    },

    handleSuspendAttemptCompleted (data) {
      console.log('[SuspendAttemptCompleted]', data)
      this.evaluateResults(data)
    },

    evaluateResults (data) {
      // Save our state
      // this.setTestStateItemState(data.state)

      if (data.state.validationMessages.length > 0) {
        // Display validation messages
        this.displayInvalidResponseMessages(data.state.validationMessages)
        // Do not proceed if we have any validationMessages
        return
      }

      // Perform next action such as navigation.
      // this.next(data.target)
    },

    /**
     * @description Handler for QTI item 'alert' messages such as max selections messages.
     * @param {Object} event - object containing an icon property and a message property
     */
    displayItemAlertEvent (event) {
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: event.icon,
        html: event.message,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
        timerProgressBar: true
      })
    },

    /**
     * @description Display response validation messages if validateResponses=true.
     * @param {Array} messages - messages to be displayed
     */
    displayInvalidResponseMessages (messages) {
      messages.forEach((message) => {
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            html: message.message,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
            timerProgressBar: true
          })
      })
    },

    /**
     * @description Handler for QTI item catalog events such as 'glossary' events.
     * @param {Object} event - object containing a catalog event payload
     * Sample event schema:
     * {
     *   type: "glossary",
     *   term: "acronym",
     *   catalogIdRef: "glosscat",
     *   data: [
     *     {
     *       support: "glossary-on-screen",
     *       card: {
     *         content: ""<p>An abbreviation.</p>"",
     *         properties: {
     *           name: "qti-html-content"
     *         }
     *       }
     *     }
     *     ... additional supports in catalog based on PNP ...
     *   ]
     * }
     */
    handleItemCatalogEvent (event) {
      console.log('[ItemCatalogEvent][Type: ' + event.type + ']', event)
    },

    /**
     * @description Event handler for the QTI3Player component's 'notifyQti3PlayerReady'
     * event.  This event is fired upon mounting of the Qti3Player component.
     *
     * The Qti3Player is now ready for XML loading.
     * @param {Component} qti3Player - the Qti3Player component itself
     */
    handlePlayerReady (qti3Player) {
      this.qti3Player = qti3Player
      console.log('player ready', qti3Player)
    },

    /**
     * @description Event handler for the QTI3Player component's 'notifyQti3ItemReady'
     * event.  This event is fired upon completion of the qti-assessment-item
     * component's loading of XML.
     */
    handleItemReady (qti3Player) {
      this.$emit('resetResponses')
      console.log('itemReady', qti3Player)
    }

  },

  created () {
  },

  mounted () {
    this.initialize()
  }
}
</script>

<style>
.hottext {
  color: var(--cyan-blue);
  background-color: var(--light);
  border: 1px solid var(--cyan-blue);
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  padding: 0rem 0.2rem 0rem 0.2rem;
  margin: 0px 6px 0px 2px
}
.hottextSelected {
  color: var(--light);
  background-color: var(--cyan-blue);
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 0rem 0.2rem 0rem 0.2rem;
}
</style>
