<template>
<!-- ========== Left Sidebar Start ========== -->

<!--- Sidemenu -->
<div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
        <template v-for="item in menuItems">
            <li class="menu-title" v-if="item.isTitle" :key="item.id">
                {{ item.label }}
            </li>
            <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
                <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent" :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
                    <i :class="`mdi ${item.icon}`" v-if="item.icon"></i>
                    <span>{{ item.label }}</span>
                    <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ item.badge.text }}</span>
                </a>

                <a v-if="!hasItems(item)" @click.prevent="handleItemSelected" :data-identifier="`${item.link}`" class="side-nav-link-ref">
                    <i :class="`mdi ${item.icon}`" v-if="item.icon"></i>
                    <span>{{ item.label }}</span>
                    <span :class="`badge rounded-pill bg-${item.badge.variant} float-end`" v-if="item.badge">{{ item.badge.text }}</span>
                </a>

                <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
                    <li v-for="(subitem, index) of item.subItems" :key="index">
                        <a v-if="!hasItems(subitem)" @click.prevent="handleItemSelected" :data-identifier="`${subitem.link}`" class="side-nav-link-ref">{{ subitem.label }}</a>
                        <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{ subitem.label }}</a>
                        <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                            <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                                <a @click.prevent="handleItemSelected" :data-identifier="`${subSubitem.link}`" class="side-nav-link-ref">{{ subSubitem.label }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </template>
    </ul>
</div>
<!-- Sidebar -->
</template>

<script>
import MetisMenu from 'metismenujs/dist/metismenujs'
import { menuItems } from './menu'

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      menuData: null,
      lastItemSelectedElement: null
    }
  },

  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems (item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false
    },

    toggleMenu (event) {
      event.currentTarget.nextElementSibling.classList.toggle('mm-show')
    },

    handleItemSelected (event) {
      const element = event.target.closest('a.side-nav-link-ref')
      const identifier = element.getAttribute('data-identifier')

      if (identifier === 'changelog') {
        this.handleChangelogSelected(element)
        return
      }

      this.toggleActiveClass(element)
      this.$emit('itemSelected', identifier)
    },

    handleChangelogSelected (element) {
      this.toggleActiveClass(element)
      this.$emit('changelogSelected')
    },

    toggleActiveClass (element) {
      if (this.lastItemSelectedElement !== null) {
        this.lastItemSelectedElement.classList.remove('mm-active')
      }

      this.lastItemSelectedElement = element
      element.classList.add('mm-active')
    }

  },

  mounted () {
    // eslint-disable-next-line no-unused-vars
    let menuRef = new MetisMenu('#side-menu')
    let links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null
    let paths = []
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname'])
    }
    let itemIndex = paths.indexOf(window.location.pathname)
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/')
      const item = window.location.pathname.substr(0, strIndex).toString()
      matchingMenuItem = links[paths.indexOf(item)]
    } else {
      matchingMenuItem = links[itemIndex]
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      let parent = matchingMenuItem.parentElement

      /**
       * TODO: This is a hideous, hard coded way of expading/activating parent menu dropdown and
       * working until level 3.
       */
      if (parent) {
        parent.classList.add('mm-active')
        const parent2 = parent.parentElement.closest('ul')
        if (parent2 && parent2.id !== 'side-menu') {
          parent2.classList.add('mm-show')
          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.add('mm-active')
            let childAnchor = parent3.querySelector('.has-arrow')
            let childDropdown = parent3.querySelector('.has-dropdown')
            if (childAnchor) childAnchor.classList.add('mm-active')
            if (childDropdown) childDropdown.classList.add('mm-active')

            const parent4 = parent3.parentElement
            if (parent4 && parent4.id !== 'side-menu') {
              parent4.classList.add('mm-show')
              const parent5 = parent4.parentElement
              if (parent5 && parent5.id !== 'side-menu') {
                parent5.classList.add('mm-active')
                const childanchor = parent5.querySelector('.is-parent')
                if (childanchor && parent5.id !== 'side-menu') {
                  childanchor.classList.add('mm-active')
                }
              }
            }
          }
        }
      }
    }
  } // end mounted

}
</script>

<style scoped>
.side-nav-link-ref {
  cursor: pointer;
}
</style>
