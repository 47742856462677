<template>
  <header id="page-topbar">
      <div class="navbar-header">
          <!-- LOGO and Hamburger -->
          <div class="d-flex">
            <div class="navbar-brand-box">
                <a href="index.html" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/savvas-favicon.ico" alt="" height="22">
                    </span>
                    <span class="logo-lg">
                        <img src="assets/images/savvas-logo.png" alt="" height="17">
                    </span>
                </a>
            </div>
            <button
              type="button"
              class="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              @click="toggleMenu">
              <i class="mdi mdi-backburger"></i>
            </button>
          </div>
          <!-- Link to Doc 
          <div class="d-flex">
              <div class="d-inline-block">
                <a href="https://docs.google.com/document/d/1KCImUAk-2uBqSOHRO1lJa1orrzF4b8cl/edit?usp=sharing&ouid=110530207701868607426&rtpof=true&sd=true"
                  class="mt-3 font-size-18 waves-effect"
                  target="_blank"><h5>
                  <span class="text-primary mx-1">QTI 3 Player Doc</span>
                  <i class="mdi mdi-open-in-new text-primary"></i></h5>
                </a>
              </div>
          </div> -->
          <!-- Version badge -->
          <div class="d-flex">
              <div class="d-inline-block">
                  <button @click="logout" class="btn btn-sm btn-outline-primary">Log out</button>
                  <h5 class="mt-1"><span class="badge badge-secondary float-right">v0.4.3</span></h5>
              </div>
          </div>
      </div>
  </header>
</template>

<script>
/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      text: null,
      value: null,
    }
  },

  methods: {
    toggleMenu () {
      this.$parent.toggleMenu()
    },

    logout () {
      this.$parent.logout()
    }
  }
}
</script>
