<template>
  <div>
    <div class="card controller-outcome-container response-card">
      <div class="display-response">
        <a class="response" href="#" @click="getEndAttemptState">Get Responses: </a>
        <span class="initial-response">[ Click Get Responses ]</span>
        <div class="variable-response">
          <span class="response-data"> 
            <div v-for="response of responses" :key="response.identifier">
              &nbsp;Variable {{response.identifier}} = <span >[{{response.value}}]</span>
            </div>
          </span>
        </div>
      </div>
      <div class="display-response">
        <a class="score" href="#" @click="getEndAttemptState">Score Responses: &nbsp;</a>
        <div class="initial-response">[ Click Score Responses ]</div>
        <div class="score-data">
          <div>
            Score = {{score}}
          </div>
          <div>
            Max Score = {{maxScore}}
          </div>  
        </div>                
      </div>
    </div>
    <ContentView ref="contentcanvas" v-bind:identifier="xmlSchema" @endAttemptComplete="handleEndAttemptComplete" @resetResponses="onResetResponses"/>
  </div>
</template>
<script>
import ContentView from '../components/ContentView.vue';
export default {
    name: 'player',
    components: {
      ContentView
    },
    data() {
      return {
        myFrame: null,
        xmlSchema: '',
        score: 0,
        maxScore: 0,
        responses: []
      }
    },

    methods: {

      getEndAttemptState(event) {
        event.target.nextSibling.style.display = 'none';
        event.target.classList.contains('score') ? this.$el.querySelector('.score-data').style.display = 'block' : '';
        event.target.classList.contains('response') ? this.$el.querySelector('.variable-response').style.display = 'block' : '';
        this.$refs.contentcanvas.qti3Player.endAttempt('navigateNextItem');
        this.getScore();
        this.getCurrentResponse();
      },

      handleEndAttemptComplete(event) {
        this.endAttemptState = event;
      },

      getScore() {
        this.score = (this.endAttemptState.state.outcomeVariables.find((item) => item.identifier === 'SCORE')?.value) || 0;
        this.maxScore = (this.endAttemptState.state.outcomeVariables.find((item) => item.identifier === 'MAXSCORE')?.value) || 0;
      },

      getCurrentResponse() {
        this.responses = []
        for (const obj of this.endAttemptState.state.responseVariables) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty('correctResponse') && !['SOLREQUEST, HINTREQUEST, CHECKANSWER'].includes(obj.identifier)) {
              this.responses.push(obj);
            }
        }
      },

      onResetResponses() {
        this.$el.querySelector('.score-data').style.display = 'none';
        this.$el.querySelector('.variable-response').style.display = 'none';
        this.$el.querySelectorAll('.initial-response').forEach(initialResponse => {
          initialResponse.style.display = 'block';
        })
      },
    },

    created() {
        window.addEventListener("message", (e) => {
          var data = e.data;
          if(typeof data === 'string' && data != '') {
            this.xmlSchema = data
            console.log("SHAKEHAND RECEIVED FROM CHILD")
          }
        });
      }
}
</script>
<style>
  .response-card {
    padding: 15px;
    margin: 15px;
  }
  .display-response {
    display: flex;
  }
  .variable-response, .score-data {
    display: none;
  }
</style>