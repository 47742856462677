<template>
  <b-modal
    id="modal-xml"
    size="lg"
    title="QTI 3 XML"
    title-class="font-18"
    scrollable
    hide-footer>
    {{itemXml}}
  </b-modal>
</template>

<script>
import { ItemFactory } from '@/helpers/ItemFactory'

export default {
  name: 'ContentXmlView',

  props: {
    identifier: {
      type: String,
      required: true,
      default: ''  }
  },

  data () {
    return {
      /*
       * This is our list of items, item XML, item GUID's
       */
      items: null,
      /*
       * Raw QTI3 Item XML to be displayed
       */
      itemXml: ''
    }
  },

  watch: {
    /**
     * @description A menu change will trigger an identifier update.  Watch this
     * and load the appropriate item xml from the factory.
     */
    identifier () {
      let itemIndex = this.items.findIndex(item => item.identifier == this.identifier)
      this.itemXml = this.loadItemAtIndex(itemIndex)
    }
  },

  methods: {
    initialize () {
      // Load items...all of them.
      this.items = new ItemFactory().loadAll()
    },

    loadItemAtIndex (index) {
      if (index === null) return ''
      if (index < 0) return ''
      return this.items[index].xml
    }
  },

  mounted () {
    this.initialize()
  }
}
</script>
