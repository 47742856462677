// NEW - Import the profile component
import Vue from 'vue';
import Router from 'vue-router';
import testharness from '../shared/pages/TestHarness.vue';
import player from '../shared/pages/Qti3Player.vue';

// import ExternalApi from '@/views/ExternalApi';
import { authGuard } from '@/auth/authGuard';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "testharness",
        component: testharness,
        beforeEnter: authGuard
      },
      {
        path: "/player",
        name: "player",
        component: player,
        beforeEnter: authGuard
      },
    ],
  });

export default router;
